import {useState} from 'react';
import * as tf from '@tensorflow/tfjs';
import './App.css';
import model from "./modelLoader";

export default function Form() {
  const [step, setStep] = useState(1)
  const [image, setImage] = useState(null);
  const [userImage, setUserImage] = useState(false);
  const [fileName, setFileName] = useState('');
  const [file, setFile] = useState('');
  const [imageData, setImageData] = useState('');
  const [animalResult, setAnimalResult] = useState('');

  let imageUrl;

  const handleSubmit = (event) => {
      event.preventDefault();

      if(!imageData) {
          alert('Veuillez sélectionner une image')
          return
      }

      setStep(2)
        let tensor = tf.browser.fromPixels(imageData);
        const resized = tf.image.resizeBilinear(tensor, [200, 200]).toFloat();
        const batched = resized.expandDims(0)
      model(batched)
          .then(animal => {
              setAnimalResult(animal)
              setStep(3)
          })
          .catch(error => {
              console.error(error);
          });

    }
  function loadXHR(url) {

    return new Promise(function(resolve, reject) {
        try {
            var xhr = new XMLHttpRequest();
            xhr.open("GET", url);
            xhr.responseType = "blob";
            xhr.onerror = function() {reject("Network error.")};
            xhr.onload = function() {
                if (xhr.status === 200) {resolve(xhr.response)}
                else {reject("Loading error:" + xhr.statusText)}
            };
            xhr.send();
        }
        catch(err) {reject(err.message)}
    });
}

  const handleFileChange = (event) => {
      if (event.target.files && event.target.files[0]) {
          setFile(event.target.files[0]);
          setFileName(event.target.files[0].name);
          setImage(URL.createObjectURL(event.target.files[0]));
          imageUrl = URL.createObjectURL(event.target.files[0]);

          loadXHR(imageUrl).then(function(blob) {
              const reader = new FileReader();
              reader.addEventListener("loadend", () => {

              });
              reader.readAsArrayBuffer(blob);
              reader.onload = (function(theFile) {
                let image = new Image();

                image.src = imageUrl;
                setImageData(image);
            });
          });
          setUserImage(true)
      }
  }

    return (
        <>
            <form onSubmit={handleSubmit}>
                <div id="breadcrumb">
                    <div className={'step'}>
                        <div className={`stepNumber ${step === 1 ? "stepActive" : ""} `}>1</div>
                        <div className={"stepTitle"}>Téléverser</div>
                    </div>
                    <div className={"ellipsis"}> ...</div>
                    <div className={'step'}>
                        <div className={`stepNumber ${step === 2 ? "stepActive" : ""} `}>2</div>
                        <div className={"stepTitle"}>Vérification</div>
                    </div>
                    <div className={"ellipsis"}> ...</div>
                    <div className={'step'}>
                        <div className={`stepNumber ${step === 3 ? "stepActive" : ""} `}>3</div>
                        <div className={"stepTitle"}>Réponse</div>
                    </div>
                    {animalResult !== '' ? <p>Vous avez rencontré: {animalResult}</p> : null}

                </div>
                <div id="inputContainer">
                    <div className="inputElement">
                        <label htmlFor="image">
                            {!userImage && <img src={"img/image.png"} alt="Preview" />}
                            {userImage && <img src={image} alt="Preview" className={"uploadedFile"}/>}
                        </label>
                        <input type="file" id="image" name="image"
                               accept=".jpg, .jpeg, .png"
                               onChange={handleFileChange}
                        />
                    </div>
                    <div className="inputElement">
                        <label htmlFor="video">
                            <img src={"img/video.png"} alt="Importer une image" id="uploadImage"/>
                        </label>
                        {/*<input type="file" id="video" name="video"*/}
                        {/*       accept=".jpg, .jpeg, .png"*/}
                        {/*       onChange={(e) => handleFileChange()}*/}
                        {/*/>*/}
                    </div>
                </div>

                <div id="submitContainer">
                    <div id="requirements">
                        <p id="req1">Images</p>
                        <p id="req2">JPG, PNG</p>
                    </div>
                    <button type="submit" id="submitButton">
                        Téléverser
                    </button>
                </div>
            </form>
        </>
    );
}

