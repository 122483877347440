import * as tf from '@tensorflow/tfjs';

export default function model(file) {

    return new Promise(async (resolve, reject) => {
        try {
            const model = await tf.loadLayersModel('model/model.json');
            let res = model.predict(file);
            let resultIndex = res.argMax(1).arraySync();

            let animal;
            if (resultIndex[0] == 0) {
                animal = 'Barracudas';
            } else if (resultIndex[0] == 1) {
                animal = 'Hervia';
            } else if (resultIndex[0] == 2) {
                animal = 'Mérous';
            } else if (resultIndex[0] == 3) {
                animal = 'Murène Commune';
            } else if (resultIndex[0] == 4) {
                animal = 'Poulpe';
            }
            resolve(animal);
        } catch (error) {
            reject(error);
        }
    });

}


