import './App.css';
import Form from "./Form";

function App() {
  return (
    <div className="App">

      <header className="header">
        <img src={'img/logo.png'} className="logo" alt="logo" />
        <div id="items">
          <div>Accueil</div>
          {/*<div>item 2</div>*/}
          {/*<div>item 3</div>*/}
        </div>
        <div id="loginButton">
          <div>Connexion</div>
          <div id="register">Inscription</div>
        </div>
      </header>

      <div id="wrap">
        <p id="title">
          Plongez dans l'univers fascinant de la reconnaissance d'animaux marins
        </p>
        <p id="subTitle">
          Notre outil de reconnaissance vous permet de transformer chaque plongée en une opportunité d'exploration et de découverte
        </p>

        <Form/>
      </div>
    </div>
  );
}

export default App;
